<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    document.title = 'Список дел'
  },
}
</script>
